import { buildCurrentUserUrl } from '../../helpers/urls';

export default async function isLoggedIn() {
  const url = buildCurrentUserUrl();
  if (!url) {
    return null;
  }
  const result = await fetch(url, {
    credentials: 'include',
    mode: 'cors',
  });
  if (result.ok) {
    const json = await result.json();
    return json?.id;
  }
  return null;
}
