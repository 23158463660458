import React, { useState, useEffect, useMemo } from 'react';
import App from 'next/app';
import { useRouter } from 'next/router';
import requestIp from 'request-ip';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ReactQueryDevtools } from 'react-query/devtools';
import Bugsnag from '@bugsnag/js';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { I18nProvider, Locale } from '@procore/core-react';
import LaunchDarkly from '../components/LaunchDarkly/LaunchDarkly';
import theme from '../styles/theme';
import '../styles/responsive-fonts.css';
import '../styles/global.css';
import AppContext from '../components/AppContext/AppContext';
import isLoggedIn from '../data/monolith/is-logged-in';
import MarketingTracking from '../components/Marketing/MarketingTracking';
import enUS from '../locales/en.json';
import enCA from '../locales/en-CA.json';
import { setAnalyticsUser } from '../components/Analytics/utilities';
import { init as initializeBugsnag } from '../lib/bugsnag/bugsnag';
import '../ignoreNoisyWarnings';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

initializeBugsnag();

const reportAccessibility = (): void => {
  /* istanbul ignore else */
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const config = {
      rules: [
        {
          id: 'color-contrast',
          enabled: false, // until WCAG 3 ~2023
        },
      ],
    };
    const context = {
      exclude: [
        ['.A11yException'],
        ['.ReactQueryDevtoolsPanel'],
        ['.ReactQueryDevtools'],
        ['.gm-style'],
      ],
    };
    // eslint-disable-next-line import/no-extraneous-dependencies
    const axe = require('@axe-core/react');
    const ReactDOM = require('react-dom');
    axe(React, ReactDOM, 1000, config, context);
  }
};
reportAccessibility();

MyApp.getInitialProps = async (appContext: any) => {
  const ip = appContext.ctx.req
    ? requestIp?.getClientIp(appContext.ctx.req) ?? 'anonymous'
    : 'anonymous';
  return {
    ...App.getInitialProps(appContext),
    pageProps: {
      ip,
      children: null,
    },
  };
};

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function AppProvider({ isUserLoggedIn, locale, children }) {
  const translations = {
    en: null,
  };
  if (locale === 'en-CA') {
    translations.en = enUS;
    translations[locale] = enCA;
  } else {
    translations['en-US'] = enUS;
  }
  const isUserLoggedInMemo = useMemo(
    () => ({ isUserLoggedIn }),
    [isUserLoggedIn]
  );

  return (
    <I18nProvider translations={translations} locale={locale as Locale}>
      <AppContext.Provider value={isUserLoggedInMemo}>
        {children}
      </AppContext.Provider>
    </I18nProvider>
  );
}

const Main = ({
  Component,
  pageProps,
  isUserLoggedIn,
  queryClient,
  locale,
}): JSX.Element => {
  return (
    <StyledEngineProvider injectFirst>
      <AppProvider isUserLoggedIn={isUserLoggedIn} locale={locale}>
        <Head>
          <title>Procore Construction Network</title>
          <meta
            name="description"
            content="Connect with thousands of commercial contractors on Procore. Search, find and connect with subcontractors & general contractors on one platform, Procore."
          />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
            key="viewport-meta"
          />
        </Head>
        <MarketingTracking />
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ThemeProvider theme={theme}>
                {/* CSS Reset */}
                <CssBaseline />
                <LaunchDarkly ip={pageProps.ip}>
                  <Component {...pageProps} />
                </LaunchDarkly>
              </ThemeProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
          </QueryClientProvider>
        </ErrorBoundary>
      </AppProvider>
    </StyledEngineProvider>
  );
};

/*
 * Controls the initialization of pages.
 * Docs: https://nextjs.org/docs/advanced-features/custom-app
 */
export default function MyApp({
  Component,
  pageProps,
}: AppProps<{ dehydratedState: unknown }>) {
  const { locale } = useRouter();
  const [queryClient] = useState(() => new QueryClient());
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector('#jss-server-side');
    /* istanbul ignore next */
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    isLoggedIn().then(
      (userId) => {
        setAnalyticsUser(userId);
        setIsUserLoggedIn(!!userId);
      },
      () => {
        setIsUserLoggedIn(false);
      }
    );
  }, []);

  return (
    <Main
      Component={Component}
      pageProps={pageProps}
      isUserLoggedIn={isUserLoggedIn}
      queryClient={queryClient}
      locale={locale}
    />
  );
}
