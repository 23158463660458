import React from 'react';
import { createTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import colors from './colors';

const originalTheme = createTheme();

const smBold = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '2rem',
  letterSpacing: '0.25px',
};

const smRegular = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '2rem',
  letterSpacing: '0.25px',
  color: colors.gray.l45,
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.gray.l90,
      contrastText: colors.gray.l15,
    },
    secondary: {
      main: colors.orange.l50,
    },
    text: {
      primary: colors.gray.l15,
    },
    error: {
      main: colors.red.l50,
    },
    background: {
      default: colors.white,
      paper: colors.gray.l96,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.8rem',
      fontWeight: 700,
      lineHeight: '3.2rem',
      letterSpacing: '0.15px',
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: '2.8rem',
      letterSpacing: '0.15px',
    },
    h3: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '2.4rem',
      letterSpacing: '0.15px',
    },
    h4: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '2rem',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '2.4rem',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '2.0rem',
      letterSpacing: '0.15px',
      color: colors.black,
    },
    button: {
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: '0.21px',
      lineHeight: '2.4rem',
      height: '48px',
      textTransform: 'none',
    },
    body1: {
      fontWeight: 600,
      fontSize: '14px',
      letterSpacing: '0.25px',
      lineHeight: '2rem',
    },
    body2: {
      // Bold body text
      fontWeight: 700,
      fontSize: '14px',
      letterSpacing: '0.25px',
      lineHeight: '2rem',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: 'textPrimary',
        underline: 'none',
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 600,
          '&:hover': {
            color: colors.orange.l50,
          },
          letterSpacing: '0.15px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          background: colors.white,
          maxHeight: '48px',
          padding: '16px 40px 16px 16px',
        },
        select: {
          borderRadius: '4px',
          minWidth: '120px',
          '&:focus': {
            backgroundColor: colors.white,
            borderRadius: '4px',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <ChevronRightIcon style={{ fontSize: 8 }} />,
        'aria-label': 'breadcrumb',
      },
      styleOverrides: {
        root: {
          ...smRegular,
        },
        li: {
          '& a': {
            ...smBold,
          },
          '&:last-child': {
            ...smBold,
            color: colors.gray.l15,
          },
          '&:last-child .MuiTypography-body1': {
            ...smBold,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        'spacing-xs-3': {
          margin: '-12px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [originalTheme.breakpoints.down('lg')]: {
            paddingLeft: '36px',
            paddingRight: '36px',
          },
          [originalTheme.breakpoints.up('lg')]: {
            maxWidth: '1680px',
            paddingLeft: '60px',
            paddingRight: '60px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: '0 18px',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: undefined,
          '&.MuiTab-root.Mui-selected': {
            color: 'inherit',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked:not(.Mui-disabled)': {
            color: colors.blue.l50,
          },
          '&.Mui-disabled': {
            color: colors.blue.l85,
          },
          '&:not(.Mui-checked) .PrivateSwitchBase-input': {
            width: 'auto',
            height: 'auto',
            top: 'auto',
            left: 'auto',
            opacity: '1',
            '&::before': {
              content: '""',
              position: 'absolute',
              background: colors.white,
              height: '100%',
              width: '100%',
            },
          },
          color: 'rgb(172, 181, 185)',
          padding: '0',
          margin: '0 8px',
          borderRadius: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.gray.l15,
          borderRadius: '2px',
        },
        arrow: {
          color: colors.gray.l15,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          '&:hover': {
            backgroundColor: colors.gray.l85,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.gray.l90,
            color: colors.black,
            opacity: '100%',
          },
          '&.Mui-selected': {
            backgroundColor: `${colors.black} !important`,
            color: colors.white,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: colors.white,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            backgroundColor: colors.gray.l96,
            '-webkit-appearance': 'none',
            '&:vertical': {
              width: '10px',
            },
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '16px',
            backgroundColor: colors.gray.l70,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.white,
            '&:hover': {
              backgroundColor: colors.white,
            },
          },
        },
        button: {
          '&:hover': {
            backgroundColor: colors.white,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          paddingTop: '12px',
          paddingBottom: '12px',
        },
        regular: {
          minHeight: '64px!important',
        },
      },
    },
    // Default styles for Select placeholder text
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#232729',
          fontWeight: 600,
          '&.MuiInputLabel-root.MuiFormLabel-root': {
            display: 'block',
            transform: 'none',
            top: '16px',
            left: '26px',
          },
          '&.MuiFormLabel-root.MuiFormLabel-filled': {
            display: 'none',
          },
          '&.Mui-focused': {
            color: colors.black,
          },
        },
        formControl: {
          top: '-3px',
          left: '4px',
        },
        shrink: {
          display: 'none',
        },
      },
    },
    // Error messages for form fields
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          display: 'none',
          '&.Mui-error': {
            display: 'block',
            fontSize: '1.2rem',
            marginLeft: 0,
            fontWeight: 400,
            lineHeight: '1.6rem',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: colors.gray.l96,
          color: colors.gray.l15,
          height: '24px',
          fontSize: '12px',
          borderRadius: '21px',
          textTransform: 'uppercase',
          fontWeight: 600,
        },
        colorPrimary: {
          backgroundColor: colors.blue.l50,
          margin: '2px',
          padding: '2px',
          borderRadius: '16px',
          height: '24px',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.15px',
          color: colors.white,
          '&.MuiChip-deletableColorPrimary:focus': {
            backgroundColor: colors.blue.l50,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.gray.l70,
            opacity: '100%',
          },
          '& .MuiChip-deleteIcon': {
            width: '16px',
            height: '16px',
            marginLeft: '-4px',
            color: colors.white,
            '&:hover': {
              color: colors.white,
            },
          },
          textTransform: 'none',
        },
        colorSecondary: {
          backgroundColor: colors.orange.l94,
          color: colors.orange.l50,
          border: `1px solid ${colors.orange.l50}`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
          borderRadius: '4px',
          backgroundColor: colors.white,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px',
          '&:last-child': {
            paddingBottom: '32px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& [type=number]': {
            '-moz-appearance': 'textfield',
          },
          '& ::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '& ::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.gray.l70}`,
          borderRadius: '4px',
          cursor: 'pointer',
          flex: 1,
          letterSpacing: '0.15px',
          fontWeight: 500,
          fontSize: '14px',
          caretColor: colors.orange.l50,
          '&:hover': {
            border: `1px solid ${colors.gray.l45}`,
          },
          '&.Mui-focused': {
            '& > input': {
              boxShadow: `inset 0px -2px 0px 0px ${colors.blue.l50}`,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        },
        input: {
          cursor: 'pointer',
          padding: '16px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&.MuiInputAdornment-positionStart': {
            marginRight: 0,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '24px',
          height: '24px',
        },
      },
    },
  },
});
export default theme;
