import getConfig from 'next/config';

/* istanbul ignore next */
const { publicRuntimeConfig } = getConfig() || {};

export const buildApiUrl = (suffix: string) => {
  return `${publicRuntimeConfig.originUrl}/api/${suffix}`;
};

export const buildRevGeocodeUrl = (
  lat: string | number,
  lon: string | number
) => {
  return buildApiUrl(`here/revgeocode?lat=${lat}&lon=${lon}`);
};

export const buildSimilarUrl = (locationId: string) => {
  return buildApiUrl(`similar?locationId=${locationId}`);
};

export const buildTopUrl = (locationId: string) => {
  return buildApiUrl(`top?locationId=${locationId}`);
};

export const buildAutocompleteUrl = (query: string) => {
  return buildApiUrl(`here/autocomplete?q=${query}`);
};

export const buildGraphqlUrl = () => {
  return `${publicRuntimeConfig.selfUrl}/graphql`;
};

export const buildCurrentUserUrl = () => {
  /* istanbul ignore next */
  return publicRuntimeConfig.procoreApiDomain
    ? `${publicRuntimeConfig.procoreApiDomain}/rest/v1.0/me`
    : undefined;
};

export const buildMonolithCompanyUrl = (companyId) => {
  /* istanbul ignore next */
  return publicRuntimeConfig.procoreApiDomain
    ? `${publicRuntimeConfig.procoreApiDomain}/rest/v1.0/companies/${companyId}?view=pcn`
    : undefined;
};

export const buildPayingEntitlementUrl = (companyId) => {
  /* istanbul ignore next */
  return publicRuntimeConfig.procoreApiDomain
    ? `${publicRuntimeConfig.procoreApiDomain}/rest/v1.0/companies/${companyId}/entitlements/paying_status`
    : undefined;
};

export const buildAMSCreateOrgApiUrl = () => {
  if (process.env.STAGE === 'production') {
    return `${publicRuntimeConfig.procoreApiDomain}/ams/organizations`;
  }
  return 'https://staging1.procoretech-qa.com/ams/organizations';
};

export const buildAMSCreateAccountApiUrl = (orgId) => {
  if (process.env.STAGE === 'production') {
    return `${publicRuntimeConfig.procoreApiDomain}/ams/organizations/${orgId}/accounts/procore`;
  }
  return `https://staging1.procoretech-qa.com/ams/organizations/${orgId}/accounts/procore`;
};
