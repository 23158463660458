import React from 'react';

import getConfig from 'next/config';
import Script from 'next/script';

const { publicRuntimeConfig } = getConfig();

const MarketingTracking = () => {
  if (!publicRuntimeConfig.isProd) {
    return null;
  }

  return (
    <>
      {/* <!-- Adobe Launch --> */}
      <Script
        src="//assets.adobedtm.com/launch-EN162b61ce5645478888c92738b130b2f3.min.js"
        async
        strategy="afterInteractive"
      />
      {/* // <!-- End Adobe Launch --> */}
      {/* <!-- OneTrust Cookies Consent Notice for procore.com --> */}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="cbdfc14e-1f05-4d37-82a6-11d3228b4df5"
        strategy="afterInteractive"
      />
      <Script
        id="onetrust-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: 'function OptanonWrapper() { }',
        }}
        strategy="afterInteractive"
      />
      {/* <!-- End OneTrust Cookies Consent Notice for procore.com --> */}
      {/* <!-- Google Tag Manager --> */}
      <Script
        id="google-tag-manager-script"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NWZQ5W6');`,
        }}
        strategy="afterInteractive"
      />
      {/* <!-- End Google Tag Manager --> */}
    </>
  );
};

export default MarketingTracking;
