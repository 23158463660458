import React from 'react';
import { Box, Typography } from '@mui/material';
import Image from 'next/image';
import { useI18nContext } from '@procore/core-react';
import colors from '../../styles/colors';
import ImageLoader from '../utils/ImageLoader';
import { useStyles } from './MaintenanceComponent.styles';

export default function MaintenanceComponent() {
  const I18n = useI18nContext();
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      position="relative"
      border="1px solid white"
      width="100vw"
      height="100vh"
    >
      <Box flexDirection="column" width={1}>
        <Box
          className={classes.blackBox}
          position="relative"
          width={1}
          color={colors.gray.l15}
        >
          <h1 className={classes.title}>
            {I18n.t('components.maintenance_component.our')}
          </h1>
          <Typography className={classes.message}>
            {I18n.t('components.maintenance_component.we')}
          </Typography>
        </Box>
        <Box className={classes.logo}>
          <Image
            loader={ImageLoader}
            src="/procore_logo.svg"
            alt="Procore logo"
            width={126}
            height={15}
            loading="eager"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>
      </Box>
      <Box className={classes.person}>
        <Image
          loader={ImageLoader}
          src="/maintenance.webp"
          alt="Procore user"
          width={549}
          height={623}
          loading="eager"
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </Box>
    </Box>
  );
}
