import React, { useEffect } from 'react';
import getConfig from 'next/config';
import {
  useFlags,
  useLDClient,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import MaintenanceComponent from '../Maintenance/MaintenanceComponent';

const { publicRuntimeConfig } = getConfig();

type LaunchDarklyProps = React.FC<{
  ip: string;
  children?: React.ReactNode;
}>;

const LaunchDarkly: LaunchDarklyProps = ({ ip, children }) => {
  const ldClient = useLDClient();
  const { maintenance } = useFlags();

  useEffect(() => {
    // istanbul ignore else
    if (ldClient) {
      ldClient.identify({
        key: ip,
        ip: ip,
      });
    }
  }, [ldClient]);

  return maintenance ? <MaintenanceComponent /> : <>{children}</>;
};

export default withLDProvider({
  clientSideID: publicRuntimeConfig.launchDarklyClientSideID,
  options: {
    logger: basicLogger({ level: 'warn' }),
  },
})(LaunchDarkly) as LaunchDarklyProps;
