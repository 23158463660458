import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: colors.white,
    '& a': {
      color: colors.white,
    },
  },
  blackBox: {
    height: '50vh',
    left: '0px',
    top: '21px',
    color: colors.white,
    backgroundColor: colors.gray.l15,
    marginTop: '20vh',
  },
  title: {
    paddingLeft: '6%',
    paddingTop: '80px',
    width: 'max(60%, min(400px, 95vw))',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 'calc(8px + min(3.0vw, 48px))',
    lineHeight: 'calc(8px + min(3.0vw, 54px))',
  },
  message: {
    paddingLeft: '6%',
    paddingTop: '60px',
    width: 'max(60%, min(400px, 95vw))',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 'calc(8px + min(1.8vw, 22px))',
    lineHeight: 'calc(8px + min(1.8vw, 32px))',
    letterSpacing: '0.15px',
    fontWeight: 'normal',
  },
  link: {
    paddingLeft: '6%',
    paddingTop: '30px',
    width: 'max(60%, min(400px, 95vw))',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 'calc(8px + min(1.8vw, 16px))',
    lineHeight: 'calc(8px + min(1.8vw, 24px))',
    letterSpacing: '0.15px',
    fontWeight: 'bold',
  },
  logo: {
    position: 'absolute',
    left: '6%',
    bottom: '22vh',
    [theme.breakpoints.down('sm')]: {
      bottom: '85vh',
    },
  },
  person: {
    position: 'absolute',
    bottom: -10,
    right: 0,
    width: 'max(30%, 20px)',
    height: 'auto',
  },
}));
