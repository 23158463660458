import getConfig from 'next/config';
import amplitude, { AmplitudeClient } from 'amplitude-js';
import { useEffect } from 'react';

const { publicRuntimeConfig } = getConfig();

type ConfigType = {
  pageName?: string;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return undefined;
};

export const getUtm = () => {
  const utmCookie = decodeURIComponent(getCookie('pc_utm') || '{}');
  try {
    return JSON.parse(utmCookie);
  } catch (e) {
    return {};
  }
};

export const getGoogleId = () => {
  let result = '';
  const ga = getCookie('_ga');
  if (ga) {
    result = ga.split('.').slice(2).join('.');
  }

  return result;
};

export const getAdobeId = () => {
  let result = '';
  try {
    const idParts = decodeURI(
      document.cookie.split('AMCV_')[1].split('=')[1].split(';')[0]
    ).split('|');
    for (let i = 0; i < idParts.length; i++) {
      if (idParts[i] === 'MCMID') {
        result = idParts[i + 1];
      }
    }
  } catch {
    result = '';
  }

  return result;
};

export type Provider = 'Amplitude' | 'Adobe';

let config: ConfigType = {};
export const interactionPath = [];

const instance: AmplitudeClient = amplitude.getInstance();
instance.init(publicRuntimeConfig.amplitudeApiKey, null, {
  includeReferrer: true,
  includeGclid: true,
  includeFbclid: true,
  includeUtm: true,
});

const filterProperties = (properties): object => {
  Object.keys(properties).forEach(
    // eslint-disable-next-line no-param-reassign
    (k) => !properties[k] && delete properties[k]
  );
  return properties;
};

export const setAnalyticsUser = (userId: number) => {
  if (userId) {
    instance.setUserId(userId);
  }
};

const sendLaunchInputEvent = (type: string, name: string, label: string) => {
  (window as any).Procore = (window as any).Procore || {};
  (window as any).Procore.metrics = (window as any).Procore.metrics || {};
  (window as any).Procore.metrics.events =
    (window as any).Procore.metrics.events || [];
  if (name && type && label) {
    interactionPath.push(label);
    (window as any)?.Procore?.metrics?.events?.push({
      trigger: 'customEvent',
      type,
      name,
      label,
    });
  }
};

export const sendLaunchFormSubmitEvent = (
  formId: string,
  formInputs: string,
  formInputCount: number,
  formErrors: string,
  formErrorCount: number,
  formViewed: number,
  formSelectedCount: number,
  formComplete: number,
  formInputCompanyType: string
) => {
  (window as any).Procore = (window as any).Procore || {};
  (window as any).Procore.metrics = (window as any).Procore.metrics || {};
  (window as any).Procore.metrics.events =
    (window as any).Procore.metrics.events || [];
  if (
    formId &&
    formInputs &&
    formInputCount >= 0 &&
    formErrors !== null &&
    formErrorCount >= 0 &&
    formViewed &&
    formSelectedCount &&
    formComplete &&
    formInputCompanyType
  ) {
    (window as any)?.Procore?.metrics?.events?.push({
      trigger: 'formCompleteEvent',
      formId,
      formInputs,
      formInputCount,
      formInputCompanyType,
      formErrors,
      formErrorCount,
      formViewed,
      formSelectedCount,
      formInteractionPath: interactionPath,
      formComplete,
    });
  }
};

export const getHiddenFields = () => {
  const utm = getUtm();
  return {
    Adobe_Client_ID__c: getAdobeId(),
    Google_Client_ID__c: getGoogleId(),
    Experiment_ID__c: (window as any).Procore?.metrics?.personalization?.id,
    Form_URL__c: window.location.href,
    UTM_Campaign__c: utm?.utm_campaign,
    UTM_Content__c: utm?.utm_content,
    UTM_Medium__c: utm?.utm_medium,
    UTM_Source__c: utm?.utm_source,
    UTM_Term__c: utm?.utm_term,
    GCLID__c: utm?.gclid,
    Entry_Page__c: getCookie('pc_fp'),
    Previous_Page__c: (window.location as any).referrer,
  };
};

export const sendAnalyticsEvent = (
  eventText,
  eventLabel,
  eventAction,
  rest = {},
  providers: { [key in Provider]?: boolean } = { Amplitude: true }
) => {
  let properties = {};
  if (providers.Amplitude) {
    properties = {
      ...filterProperties({
        Label: eventLabel,
        Action: eventAction,
        URL: window.location.pathname,
        Page: config.pageName,
      }),
      ...rest,
    };
    try {
      instance.logEvent(eventText, properties);
    } catch {
      // swallow exceptions
    }
  }
  if (providers.Adobe) {
    let eventTextWithValues: string | undefined;
    if ('Values' in rest) {
      eventTextWithValues = `${eventText} ${
        (rest as { Values: string }).Values
      }`;
    }
    try {
      sendLaunchInputEvent(
        eventLabel,
        eventAction,
        'Values' in rest ? eventTextWithValues : eventText
      );
    } catch {
      // swallow exceptions
    }
  }
};

export const usePageView = (pageName: string, metadata: object = {}) => {
  config = {
    pageName,
  };

  useEffect(() => {
    sendAnalyticsEvent('Page View', pageName, 'View', metadata);
  }, []);
};

export function getAmplitudeDeviceId() {
  return instance.options.deviceId;
}
