const colors = {
  gray: {
    l10: '#171A1C',
    l15: '#232729',
    l20: '#2F3437',
    l30: '#464F53',
    l40: '#5E696E',
    l45: '#6A767C',
    l50: '#75838A',
    l60: '#919CA1',
    l70: '#ACB5B9',
    l85: '#D6DADC',
    l90: '#E3E6E8',
    l94: '#EEF0F1',
    l96: '#F4F5F6',
    l98: '#F9FAFA',
  },
  orange: {
    l10: '#331000',
    l15: '#4D1800',
    l20: '#662000',
    l30: '#993000',
    l40: '#CC4100',
    l45: '#E64900',
    l50: '#FF5100',
    l60: '#FF7433',
    l70: '#FF9666',
    l85: '#FFCBB3',
    l90: '#FFDCCC',
    l94: '#FFEAE0',
    l96: '#FFF1EB',
    l98: '#FFF8F5',
  },
  blue: {
    l10: '#06142D',
    l15: '#0A1F43',
    l20: '#0D2959',
    l30: '#133D86',
    l40: '#1952B3',
    l45: '#1D5CC9',
    l50: '#2066DF',
    l60: '#4C85E6',
    l70: '#79A3EC',
    l85: '#BCD1F5',
    l90: '#D2E0F9',
    l94: '#E4ECFB',
    l96: '#EDF2FC',
    l98: '#F6F9FE',
  },
  green: {
    l10: '#092B08',
    l15: '#0E410B',
    l20: '#13570F',
    l30: '#1C8217',
    l40: '#26AD1F',
    l45: '#2AC322',
    l50: '#2FD926',
    l60: '#59E052',
    l70: '#82E87D',
    l85: '#C1F4BE',
    l90: '#D5F7D4',
    l94: '#E6FAE5',
    l96: '#EFFCEE',
    l98: '#F6FDF6',
  },
  yellow: {
    l10: '#332B00',
    l15: '#4D4000',
    l20: '#665500',
    l30: '#998000',
    l40: '#CCAA00',
    l45: '#E6BF00',
    l50: '#FFD500',
    l60: '#FFDD33',
    l70: '#FFE666',
    l85: '#FFF2B3',
    l90: '#FFF7CC',
    l94: '#FFFAE0',
    l96: '#FFFCEB',
    l98: '#FFFDF5',
  },
  red: {
    l10: '#2E0506',
    l15: '#45080A',
    l20: '#5C0A0D',
    l30: '#8A0F13',
    l40: '#B8141A',
    l45: '#CF171D',
    l50: '#E61920',
    l60: '#EB474D',
    l70: '#F07579',
    l85: '#F7BABC',
    l90: '#FAD1D2',
    l94: '#FCE3E4',
    l96: '#FDEDEE',
    l98: '#FEF6F6',
  },
  purple: {
    l10: '#1F1122',
    l15: '#2E1933',
    l20: '#3D2244',
    l30: '#5C3267',
    l40: '#7B4389',
    l45: '#8A4C9A',
    l50: '#9A54AB',
    l60: '#AE76BC',
    l70: '#C298CD',
    l85: '#E1CCE6',
    l90: '#EBDDEE',
    l94: '#F3EAF5',
    l96: '#F7F1F8',
    l98: '#FBF8FC',
  },
  black: '#000000',
  white: '#FFFFFF',
  concrete: '#555555',
};

export default colors;
