import console from './globalWrappers/console';

export const originalError = console.error;
export const originalWarn = console.warn;
export const ignoredErrors = [
  /Warning: useLayoutEffect does nothing on the server/,
  /Warning: React does not recognize the `%s` prop on a DOM element./,
  /Warning: Prop `%s` did not match. Server: %s Client: %s%s/,
];
export const ignoredWarnings = [
  /When server rendering, you must wrap your application in an <SSRProvider>/,
  /Accessing event.type in Adobe Launch has been deprecated/,
  /styled-components: it looks like an unknown prop "/,
];

const handleIgnoredLogs = (ignoredLogs, args, logger) => {
  if (
    ignoredLogs.filter((re) => [...args].find((arg) => re.test(arg)))?.length
  ) {
    return;
  }
  logger.call(console, ...args);
};

console.error = (...args): void => {
  handleIgnoredLogs(ignoredErrors, args, originalError);
};
console.warn = (...args): void => {
  handleIgnoredLogs(ignoredWarnings, args, originalWarn);
};

export {};
