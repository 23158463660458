import getConfig from 'next/config';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const { publicRuntimeConfig } = getConfig();

export const localErrorsToIgnore = [
  'ECONNREFUSED 127.0.0.1:6379',
  'Cannot connect to Redis',
  'Connection is closed',
];

export function init() {
  // @ts-ignore _client is an available method, using this as a safeguard to not start bugsnag more than once
  if (!Bugsnag._client) {
    Bugsnag.start({
      appVersion: publicRuntimeConfig.appVersion,
      apiKey: publicRuntimeConfig.bugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: ['production', 'staging', 'development'],
      releaseStage: publicRuntimeConfig.bugsnagEnvironment,
      onError: (event) => {
        if (
          event?.originalError?.name === 'ChunkLoadError' &&
          event?.originalError?.message?.indexOf('js-agent.newrelic') > -1
        ) {
          return false;
        }
        if (publicRuntimeConfig.bugsnagEnvironment === 'development') {
          if (
            localErrorsToIgnore.some(
              (e) => event.originalError?.message?.indexOf(e) > -1
            )
          ) {
            return false;
          }
          /* eslint-disable-next-line no-console */
          console.error('Bugsnag Error', event.originalError);
          return false;
        }
        return true;
      },
    });
  }
}
